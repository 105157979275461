import 'nprogress/nprogress.css';
import React from 'react';
import { render } from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import { SettingsProvider } from './context/settings-context';
import App from './app';
import store from './store';
import { restoreSettings } from './utils/settings';

const settings = restoreSettings();

render(
  <StoreProvider store={store}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </StoreProvider>,
  document.querySelector('#app'),
);
