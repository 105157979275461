import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { me } from '../services/auth';
import { useStoreActions, useStoreState } from '../hooks';

function AuthGuard({ children }) {
  const navigate = useNavigate();
  const setUser = useStoreActions((actions) => actions.auth.setUser);
  const initialUser = useStoreState((state) => state.auth.user);
  const { data: user, error } = useSWR(
    '/auth/me',
    () =>
      me()
        .json()
        .then(({ data }) => {
          setUser(data);
          return data;
        }),
    {
      initialData: initialUser,
    },
  );

  if (error || !user) {
    navigate('auth/login');
  }

  return children;
}

export default AuthGuard;
