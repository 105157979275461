import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import SplashScreen from './splash-screen';
import { useStoreActions, useStoreState } from '../hooks';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

function initializeGoogleAPI() {
  if (!document.querySelector('#g-api')) {
    const script = document.createElement('script');
    script.id = 'g-api';
    script.src = GOOGLE_SDK_URL;

    document.head.append(script);
  }
}

const Auth = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const load = useStoreActions((actions) => actions.auth.load);
  const user = useStoreState((state) => state.auth.user);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const isSocialLoginPage = useMatch('auth/callback');
  const isGuestPage =
    pathname.startsWith('/auth') ||
    pathname.startsWith('/401') ||
    pathname.startsWith('/processo-seletivo') ||
    pathname.startsWith('/matriculaonline') ||
    pathname.startsWith('/aceite-contrato') ||
    pathname.startsWith('/aceite-finalizado') ||
    pathname.startsWith('/checkout-finalizado') ||
    pathname.startsWith('/checkout') ||
    pathname.startsWith('/checkout/:idAcordo');
  const params = new URLSearchParams(search);
  const tenant = params.get('tenant') || params.get('t');

  useEffect(() => {
    initializeGoogleAPI();
  }, []);

  useEffect(() => {
    if (tenant) {
      window.localStorage.setItem('tenant', JSON.stringify(tenant));
    }
  }, [tenant]);

  useEffect(() => {
    if (isSocialLoginPage) {
      setLoading(false);
      return;
    }

    (async () => {
      load()
        .then(() => {
          if (isGuestPage) {
            navigate('/');
          }
        })
        .catch(() => {
          if (!isGuestPage) {
            navigate('/auth/login');
          }
        })
        .finally(() => setLoading(false));
    })();
  }, [navigate, load, isGuestPage, isSocialLoginPage]);

  if (loading) {
    return <SplashScreen />;
  }

  if (!user && !isGuestPage) {
    return null;
  }

  return children;
};

export default Auth;
