/* eslint-disable unicorn/prefer-module */
import { createStore } from 'easy-peasy';
import model from '../model';

const store = createStore(model, {
  devTools: process.env.NODE_ENV === 'development',
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('../model', () => {
    store.reconfigure(model);
  });
}

export default store;
