import { useCallback } from 'react';
import { useStoreState } from '.';

function usePermissions() {
  const userIsAdmin = useStoreState((state) => state.auth.isAdmin);
  const userPermissions = useStoreState((state) => state.auth.permissions);
  const hasAnyInstituicao = useStoreState(
    (state) => (state.auth.user?.instituicoes || []).length > 0,
  );

  const hasPermission = useCallback(
    (...permissions) =>
      hasAnyInstituicao &&
      (userIsAdmin ||
        permissions.some((permission) => userPermissions.includes(permission))),
    [userPermissions, userIsAdmin, hasAnyInstituicao],
  );

  return { userPermissions, hasPermission };
}

export default usePermissions;
