import React from 'react';
import { SvgIcon } from '@material-ui/core';

function TeacherIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M4 3H18C19.11 3 20 3.9 20 5V12.08C18.45 11.82 16.92 12.18 15.68 13H12V17H13.08C12.97 17.68 12.97 18.35 13.08 19H4C2.9 19 2 18.11 2 17V5C2 3.9 2.9 3 4 3M4 7V11H10V7H4M12 7V11H18V7H12M4 13V17H10V13H4M23 22V21C23 19.67 20.33 19 19 19S15 19.67 15 21V22H23M19 14C17.9 14 17 14.9 17 16S17.9 18 19 18 21 17.11 21 16 20.11 14 19 14Z"
      />
    </SvgIcon>
  );
}

export default TeacherIcon;
