export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const PESSOA_TIPO = {
  FISICA: 1,
  JURIDICA: 2,
};

export const DOCUMENTO_TIPO = {
  RG: 1,
  CNH: 2,
  CN: 3,
  CAM: 4,
  CC: 5,
  TE: 6,
  CPF: 7,
  HE: 8,
  CCC: 9,
  CNPJ: 10,
  CQE: 11,
  CONTRATO: 12,
  CURRICULO: 13,
  RESIDENCIA: 14,
};

export const CONTATO_TIPO = {
  EMAIL: 1,
  TELEFONE_FIXO: 2,
  TELEFONE_COMERCIAL: 3,
  CELULAR: 4,
};

export const PESSOA_COR = {
  BRANCA: 1,
  PRETA: 2,
  PARDA: 3,
  AMARELA: 4,
  INDIGENA: 5,
  PREFIRO_NAO_DECLARAR: 6,
};

export const NACIONALIDADE = {
  BRASILEIRA: 1,
  BRASILEIRA_NATURALIZADA: 2,
  ESTRANGEIRA: 3,
};

export const MATRICULA_SITUACAO = {
  MATRICULADO: 1,
};

export const RESULTADO_ACADEMICO = {
  APROVADO: 1,
  REPROVADO: 2,
};

export const DOCUMENTO_SITUACAO = {
  APROVADO: 1,
  PENDENTE: 2,
  REPROVADO: 3,
};

export const EVENT_TYPE_COLOR = {
  CREATED: 'success',
  UPDATED: 'primary',
  DELETED: 'error',
  RESTORED: 'warning',
};

export const PLANO_PAGAMENTO_TIPO = {
  BOLETO: 1,
  CC: 2,
};
