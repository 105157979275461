import auth from './auth';
import notification from './notification';
import instituicao from './instituicao';

const storeModel = {
  auth,
  notification,
  instituicao,
};

export default storeModel;
