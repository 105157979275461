import React, { useRef, useReducer, useLayoutEffect } from 'react';
import { Router } from 'react-router';
import historyInstance from 'history/browser';

function BrowserRouter({ children }) {
  const historyRef = useRef();

  if (historyRef.current == null) {
    historyRef.current = historyInstance;
  }

  const history = historyRef.current;
  const [state, dispatch] = useReducer((_, action) => action, {
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(dispatch), [history]);

  return (
    <Router
      // eslint-disable-next-line react/no-children-prop
      children={children}
      action={state.action}
      location={state.location}
      navigator={history}
    />
  );
}

export default BrowserRouter;
