import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  logo: {
    userSelect: 'none',
  },
}));

function Logo(props) {
  const classes = useStyles();

  return (
    <img
      className={classes.logo}
      alt="Logo"
      src="/images/logo.png"
      {...props}
    />
  );
}

export default Logo;
