import { useState, useCallback, useEffect, useRef } from 'react';
import { dequal } from 'dequal/lite';

function useLocalStorage(key, { initialValue, watch = false } = {}) {
  const watching = useRef(watch);
  const [value, setValue] = useState(initialValue);
  const [state, setState] = useState(() => {
    try {
      const localStorageValue = window.localStorage.getItem(key);

      return localStorageValue ? JSON.parse(localStorageValue) : initialValue;
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  const set = useCallback(
    (valOrFunc) => {
      try {
        const newState =
          valOrFunc instanceof Function ? valOrFunc(state) : valOrFunc;

        window.localStorage.setItem(key, JSON.stringify(newState));
        setState(newState);
      } catch {
        // If user is in private mode or has storage restriction
        // localStorage can throw. Also JSON.stringify can throw.
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, setState],
  );

  const refresh = useCallback(
    () => {
      try {
        const localStorageValue = JSON.parse(window.localStorage.getItem(key));

        if (localStorageValue !== state) {
          set(localStorageValue);
        }

        return localStorageValue;
      } catch {
        // If user is in private mode or has storage restriction
        // localStorage can throw. Also JSON.stringify can throw.
        return state;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, setState],
  );

  const remove = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(null);
      setValue(null);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw.
    }
  }, [key, setState]);

  useEffect(() => {
    if (!watching) {
      return () => {};
    }

    const id = setInterval(() => {
      const newState = JSON.parse(window.localStorage.getItem(key)) || value;

      if (dequal(state, newState)) {
        return;
      }

      setState(newState);
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [key, state, value, watching]);

  return { state, set, remove, refresh };
}

export default useLocalStorage;
