import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  Brightness4 as DarkIcon,
  Brightness7 as LightIcon,
} from '@material-ui/icons';
import { useSettings } from '../../../hooks';
import { THEMES } from '../../../constants';

function Theme() {
  const { settings, saveSettings } = useSettings();

  const toggleTheme = () => {
    saveSettings({
      theme: settings.theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT,
    });
  };

  return (
    <Tooltip title={settings.theme === THEMES.LIGHT ? 'Escuro' : 'Claro'}>
      <IconButton
        color={settings.theme === THEMES.LIGHT ? 'default' : 'inherit'}
        onClick={toggleTheme}
      >
        {settings.theme === THEMES.LIGHT ? <DarkIcon /> : <LightIcon />}
      </IconButton>
    </Tooltip>
  );
}

export default Theme;
