import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  ListItemIcon,
} from '@material-ui/core';
import { ExitToAppOutlined as ExitToAppIcon } from '@material-ui/icons';
import { useStoreActions, useStoreState, useSettings } from '../../../hooks';
import wordsLimit from '../../../utils/words-limit';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    color:
      theme.name === 'LIGHT'
        ? theme.palette.common.white
        : theme.palette.common.black,
    backgroundColor:
      theme.name === 'LIGHT'
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
  popover: {
    width: 200,
  },
}));

function Account() {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const user = useStoreState((state) => state.auth.user);
  const logout = useStoreActions((actions) => actions.auth.logout);
  const { settings } = useSettings();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        navigate('/auth/login');
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={user.avatar || '/images/female-avatar.svg'}
        />
        <Hidden smDown>
          <Typography
            variant="h6"
            color={settings.theme === 'LIGHT' ? 'textPrimary' : 'inherit'}
          >
            {wordsLimit(user.pessoa.nome)}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Sair</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
