import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  useTheme,
  colors,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import Logo from '../../../components/logo';
import { THEMES } from '../../../constants';
import Account from './account';
import Theme from './theme';
import Instituicao from './instituicao';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.common.white,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: colors.grey[200],
        }
      : {}),
    ...(theme.name === THEMES.DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
}));

function TopBar({ onMobileNavOpen }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color={theme.name === 'LIGHT' ? 'default' : 'inherit'}
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Logo height={32} />
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Instituicao />
        <Theme />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func.isRequired,
};

export default TopBar;
