import { action } from 'easy-peasy';

const instituicao = {
  current: null,
  setCurrent: action((state, value) => {
    state.current = value;

    window.localStorage.setItem('instituicao', JSON.stringify(value));
  }),
};

export default instituicao;
