import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { useStoreState, useStoreActions } from '../../../hooks';

function Instituicao() {
  const instituicao = JSON.parse(window.localStorage.getItem('instituicao'));
  const instituicoes = useStoreState((state) => state.auth.user.instituicoes);
  const selectedInstituicao = useStoreState(
    (state) => state.instituicao.current,
  );
  const setCurrentInstituicao = useStoreActions(
    (actions) => actions.instituicao.setCurrent,
  );

  if (instituicao && !selectedInstituicao) {
    setCurrentInstituicao(instituicao);
  }

  return (
    instituicoes.length > 0 && (
      <Select
        title="Instituição"
        labelId="selected-instituicao"
        label="Instituição"
        value={selectedInstituicao?.id || ''}
        onChange={(event) => {
          setCurrentInstituicao(
            instituicoes.find((item) => item.id === event.target.value),
          );
        }}
        disableUnderline
      >
        {instituicoes.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.nome}
          </MenuItem>
        ))}
      </Select>
    )
  );
}

export default Instituicao;
