import api from '../utils/api';

export function me() {
  return api.get('tenants/me');
}


export function all() {
  return api.get('tenant/all-tenants');
}

export function getPrefix() {
  return api.get('tenant/get-prefix');
}



export function resgateTenant(id) {
  return api.get(`tenant/resgate-tenant/${id}`);
}


export function resgateStep(id) {
  return api.get(`tenant/resgate-step/${id}`);
}

export function createTenantInDatabase(data) {
  return api.post('tenant/create-database', { json: data });
}

export function update(data) {
  return api.post('tenant/update', { json: data });
}

export function addInitialUsers(data) {
  return api.post('tenant/add-initial-users', { json: data });
}


export function updateSSLCertificate(data) {
  return api.post('tenant/update-ssl-certificate', { json: data });
}

export function addCustomDomainToFirebaseHosting(data) {
  return api.post('tenant/add-custom-domain-firebase-hosting', { json: data });
}

export function createDnsRecord(data) {
  return api.post('tenant/create-dns-record', { json: data });
}

