import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useStoreActions, useStoreState } from '../hooks';

let displayed = [];

const storeDisplayed = (id) => {
  displayed = [...displayed, id];
};

const removeDisplayed = (id) => {
  displayed = [...displayed.filter((key) => id !== key)];
};

const Notifier = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const items = useStoreState((state) => state.notification.items);
  const removeNotification = useStoreActions(
    (actions) => actions.notification.remove,
  );

  useEffect(() => {
    items.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, id) => {
          if (options.onClose) {
            options.onClose(event, reason, id);
          }
        },
        onExited: (_event, id) => {
          // remove this snackbar from store
          removeNotification(id);
          removeDisplayed(id);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [items, closeSnackbar, enqueueSnackbar, removeNotification]);

  return null;
};

export default Notifier;
