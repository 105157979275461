import { useContext } from 'react';
import SettingsContext from '../context/settings-context';

function useSettings() {
  const context = useContext(SettingsContext);

  return context;
}

export default useSettings;
