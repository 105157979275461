import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import Logo from '../../components/logo';
import { THEMES } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.common.white,
        }
      : {}),
    ...(theme.name === THEMES.DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
}));

const TopBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar {...props} className={classes.root} color="primary">
      <Toolbar>
        <Logo height={32} />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
