import React, { useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import DayJSUtils from '@material-ui/pickers/adapter/dayjs';
import { SnackbarProvider } from 'notistack';
import Router from './components/browser-router';
import { createTheme } from './theme';
import dayjs from './utils/dayjs';
import ScrollReset from './components/scroll-reset';
import Notifier from './components/notifier';
import Auth from './components/auth';
import { useSettings } from './hooks';
import Routes from './routes';
import ErrorBoundary from './components/error-boundary';

const App = () => {
  const { settings } = useSettings();

  useEffect(() => {
    window.localStorage.removeItem('errors');
  }, []);

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <LocalizationProvider
        dateAdapter={DayJSUtils}
        dateLibInstance={dayjs}
        locale="pt-br"
      >
        <CssBaseline />
        <Router>
          <ErrorBoundary>
            <Auth>
              <ScrollReset />
              <Routes />
            </Auth>
          </ErrorBoundary>
        </Router>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          preventDuplicate
        >
          <Notifier />
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
