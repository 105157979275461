import api from '../utils/api';

export function login(credentials) {
  return api.post('auth/login', {
    json: credentials,
  });
}

export function logout() {
  return api.post('auth/logout');
}

export function me() {
  return api.get('auth/me');
}

export function updateToken(token) {
  if (token) {
    window.localStorage.setItem('token', JSON.stringify(token));
  } else {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('g-token');
  }
}

export function socialMe() {
  return api.get('auth/google/me', {
    searchParams: { token: JSON.parse(window.localStorage.getItem('g-token')) },
  });
}

export function socialRefreshToken() {
  return api.post('auth/google/refresh');
}
