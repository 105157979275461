import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    if (process.NODE_ENV === 'development') {
      console.error(error, errorInfo);
    }

    // Log the error to an error reporting service
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      console.error(error);
    }

    return children;
  }
}

export default ErrorBoundary;
