import history from 'history/browser';
import http from './http';

export const apiBaseUrl = () => {
  const { protocol, hostname } = window.location;

  return `${protocol}//api.${hostname}`;

  // return 'https://api.lsb.ge.sync4edu.dev';
};

const instance = http.extend({
  prefixUrl: apiBaseUrl(),
  hooks: {
    beforeRequest: [
      (request) => {
        const token = JSON.parse(window.localStorage.getItem('token'));
        const tenant = JSON.parse(window.localStorage.getItem('tenant'));
        const instituicao = JSON.parse(
          window.localStorage.getItem('instituicao'),
        );

        if (instituicao?.id && !request.url.includes('auth')) {
          request.headers.set('X-Context', instituicao.id);
        }

        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`);
        }

        if (tenant) {
          request.headers.set('X-Tenant', tenant);
        }
      },
    ],
    afterResponse: [
      (_request, _options, response) => {
        if (response.status === 422) {
          (async () => {
            const { errors } = await response.json();

            window.localStorage.setItem(
              'errors',
              JSON.stringify(Object.values(errors)),
            );
          })();
        }

        if (
          ([403, 404].includes(response.status) &&
            !window.location.pathname.startsWith('/processo-seletivo') &&
            !window.location.pathname.startsWith('/checkout') &&
            !window.location.pathname.startsWith('/checkout/:idAcordo') &&
            !window.location.pathname.startsWith('/matriculaonline-nova') &&
            !window.location.pathname.startsWith('/planos-pagamentos') &&
            !window.location.pathname.startsWith('/checkout-finalizado') &&
            !window.location.pathname.startsWith('/aceite-contrato') &&
            !window.location.pathname.startsWith('/aceite-finalizado') &&
            !window.location.pathname.startsWith('/matriculaonline-nova') &&
            !window.location.pathname.startsWith('/matriculaonline')) ||
          (response.status === 401 &&
            !window.location.pathname.startsWith('/auth') &&
            !window.location.pathname.startsWith('/planos-pagamentos') &&
            !window.location.pathname.startsWith('/processo-seletivo') &&
            !window.location.pathname.startsWith('/checkout') &&
            !window.location.pathname.startsWith('/checkout/:idAcordo') &&
            !window.location.pathname.startsWith('/aceite-contrato') &&
            !window.location.pathname.startsWith('/aceite-finalizado') &&
            !window.location.pathname.startsWith('/checkout-finalizado') &&
            !window.location.pathname.startsWith('/matriculaonline-nova') &&
            !window.location.pathname.startsWith('/matriculaonline'))
        ) {
          history.replace(`/${response.status}`);
        }

        return response;
      },
    ],
  },
});

export default instance;
