import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { storeSettings } from '../utils/settings';
import deepMerge from '../utils/deep-merge';
import { THEMES } from '../constants';

const SettingsContext = createContext();

const defaultSettings = {
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
};

export function SettingsProvider({ settings, children }) {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings,
  );

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = deepMerge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({
    responsiveFontSizes: PropTypes.bool,
    theme: PropTypes.string,
  }),
};

SettingsProvider.defaultProps = {
  settings: null,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
