import { action, computed, thunk } from 'easy-peasy';
import { login, logout, me, updateToken } from '../services/auth';
import { me as fetchTenant } from '../services/tenant';

const authModel = {
  user: null,
  tenant: null,
  loggedIn: computed((state) => state.user !== null),
  isAdmin: computed((state) => state.user && state.user.admin),
  permissions: computed((state) =>
    state.user
      ? state.user.funcoes
          .flatMap((funcao) => funcao.permissoes)
          .concat(state.user.permissoes)
          .map((permissao) => permissao.nome)
      : [],
  ),
  setUser: action((state, user) => {
    state.user = user;
  }),
  setTenant: action((state, tenant) => {
    state.tenant = tenant;
  }),
  load: thunk(async (actions) => {
    try {
      const [{ data: userData }, { data: tenantData }] = await Promise.all([
        me().json(),
        fetchTenant().json(),
      ]);

      const instituicao = JSON.parse(
        window.localStorage.getItem('instituicao'),
      );

      if (userData.instituicoes.length > 0) {
        const hasInstituicao = userData.instituicoes.some(
          (item) => item.id === instituicao?.id,
        );

        if (!instituicao || !hasInstituicao) {
          window.localStorage.setItem(
            'instituicao',
            JSON.stringify(userData.instituicoes[0]),
          );
        }
      } else {
        window.localStorage.removeItem('instituicao');
      }

      actions.setUser(userData);
      actions.setTenant(tenantData);
    } catch (error) {
      updateToken();
      actions.setUser(null);

      throw new Error(error);
    }
  }),
  login: thunk(async (_actions, { username, password }) => {
    try {
      const data = await login({ username, password }).json();

      updateToken(data.accessToken);
    } catch (error) {
      throw new Error(error);
    }
  }),
  logout: thunk(async (actions) => {
    try {
      await logout();

      updateToken();
      actions.setUser(null);
    } catch (error) {
      throw new Error(error);
    }
  }),
};

export default authModel;
