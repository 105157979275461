import { action } from 'easy-peasy';

const notificationModel = {
  items: [],
  enqueue: action((state, item) => {
    state.items.push({
      ...item,
      key: item.key || Date.now() + Math.random(),
    });
  }),
  close: action((state, key) => {
    const dismissAll = !key;

    state.items = state.items.map((item) =>
      dismissAll || item.key === key
        ? { ...item, dismissed: true }
        : { ...item },
    );
  }),
  remove: action((state, key) => {
    state.items = state.items.filter((item) => item.key !== key);
  }),
};

export default notificationModel;
