import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Divider,
  Avatar,
  List,
  ListSubheader,
  Typography,
  Hidden,
  Link,
  Box,
} from '@material-ui/core';
import { Scrollbar } from 'react-scrollbars-custom';
import navConfig from './nav-config';
import NavItem from '../../components/nav-item';
import { useStoreState, usePermissions } from '../../hooks';
import Logo from '../../components/logo';
import wordsLimit from '../../utils/words-limit';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const renderNavItems = ({
  items,
  subheader,
  key,
  pathname,
  hasPermission,
  depth = 0,
}) => {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}

      {items.reduce((acc, item) => {
        const showItem = hasPermission(item.permission) || item.href === '/';
        const showItemGroup =
          item.items && item.items.some((i) => hasPermission(i.permission));

        if (showItemGroup) {
          const open = item.items.some((c) => !!matchPath(c.href, pathname));

          acc.push(
            <NavItem
              key={item.href}
              depth={depth}
              icon={item.icon}
              label={item.label}
              isOpen={open}
              title={item.title}
            >
              {renderNavItems({
                pathname,
                hasPermission,
                depth: depth + 1,
                items: item.items,
              })}
            </NavItem>,
          );
        } else if (showItem) {
          acc.push(
            <NavItem
              key={item.href}
              depth={depth}
              href={item.href}
              icon={item.icon}
              label={item.label}
              title={item.title}
            />,
          );
        }

        return acc;
      }, [])}
    </List>
  );
};

const Navbar = ({ openMobile, onMobileClose }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const user = useStoreState((state) => state.auth.user);
  const { hasPermission } = usePermissions();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Scrollbar
        noScrollX
        mobileNative
        disableTracksWidthCompensation
        disableTracksMousewheelScrolling
      >
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <Logo height={32} />
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar || '/images/female-avatar.svg'}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center" style={{ wordBreak: 'break-word' }}>
            <Link
              component={RouterLink}
              to="/"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {wordsLimit(user.pessoa.nome, 2)}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.key}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname,
                hasPermission,
              })}
            </List>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

Navbar.propTypes = {
  openMobile: PropTypes.bool,
  onMobileClose: PropTypes.func,
};

Navbar.defaultProps = {
  openMobile: false,
  onMobileClose: null,
};

export default Navbar;
