/* eslint-disable no-param-reassign */
const isObject = (obj) => obj && typeof obj === 'object';

function deepMergeInner(target, source) {
  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepMergeInner({ ...targetValue }, sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export default function deepMerge(...objects) {
  const target = objects.shift();
  let source;

  // eslint-disable-next-line no-cond-assign
  while ((source = objects.shift())) {
    deepMergeInner(target, source);
  }

  return target;
}
